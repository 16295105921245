import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  addonModules: [], // Initially empty array for addon modules
};

const addonModulesSlice = createSlice({
  name: 'addonModules',
  initialState,
  reducers: {
    assignAddonModules:(state, action)=>{
      state.addonModules = action.payload;
    },
    addAddonModule: (state, action) => {
      console.log(action,'addonmodules');
      // Add an addon module to addonModules array
      state.addonModules.push(action.payload);
    },
    removeAddonModule: (state, action) => {
      // Remove an addon module from addonModules array
      state.addonModules = state.addonModules.filter(mod => mod.id !== action.payload.id);
    },
    toggleAddonModule: (state, action) => {
      // Toggle addon module (add if not present, remove if present)
      const existingIndex = state.addonModules.findIndex(mod => mod.id === action.payload.id);
      if (existingIndex !== -1) {
        state.addonModules.splice(existingIndex, 1);
      } else {
        state.addonModules.push(action.payload);
      }
    },
  },
});


export const {assignAddonModules, addAddonModule, removeAddonModule, toggleAddonModule } = addonModulesSlice.actions;

export const selectAddonModules = state => state.addonModules.addonModules;

export default addonModulesSlice.reducer;

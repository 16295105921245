// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import {thunk} from 'redux-thunk';
import adminAuthReducer from './slices/adminAuthSlice';
import selectedPlanReducer from './slices/selectedPlanSlice';
import addonModulesReducer from './slices/addonModulesSlice';
import skipGetReducer from './slices/skipGetSlice';
import companyRegistrationSlice from './slices/companyRegistrationSlice';
import emailReducer from './slices/emailSlice';
import passwordReducer from './slices/passwordSlice';
import orderRducer from './slices/orderSlice';

const store = configureStore({
  reducer: {
    adminAuth: adminAuthReducer,
    selectedPlan: selectedPlanReducer,
    addonModules: addonModulesReducer,
    skipGet: skipGetReducer,
    companyRegistration: companyRegistrationSlice,
    email: emailReducer,
    password: passwordReducer,
    order: orderRducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export default store;

import React from "react";
import { AllRoute } from "./routes/AllRoute";
import store from "./Redux/store";
import { Provider } from 'react-redux';

function App() {

  return (
    <>
    <Provider store={store}>
      <AllRoute />
    </Provider>
    </>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { checkTokenExpiry } from '../../Redux/slices/adminAuthSlice';
import { deletepPlan, changePlanStatus, getAllPlan, editPlan, getAllModule, createPlan } from '../../services/Admin/api';
import { Button, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

const AdminPlan = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token } = useSelector((state) => state.adminAuth);
    const [tableData, setTableData] = useState([]);
    const [moduleTableData, setModuleTableData] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [planName, setPlanName] = useState('');
    const [planCategory, setPlanCategory] = useState('');
    const [mostPopular, setMostPopular] = useState(false);
    const [monthlyPrice, setMonthlyPrice] = useState(0);
    const [annualPrice, setAnnualPrice] = useState(0);
    const [display, setDisplay] = useState(false);
    const [discount, setDiscount] = useState('');
    const [planDescription, setPlanDescription] = useState('');
    const [allModule, setAllModuleData] = useState([]);
    const [userLimit, setUserLimit] = useState('');
    const [buttonModules, setButtonModules] = useState([]);
    const [extractedIdOfModule, setExtractedIdOfModule] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [moduleIdsForNewCreate, setModuleIdsForNewCreate] = useState([]);
    const statusArray = ['Active', 'Suspend'];

    const [showPlanDetails, setShowPlanDetails] = useState(false);
    const handleClosePlanDetails = () => setShowPlanDetails(false);
    const handleShowplanDetails = (plan) => {
        console.log("Plan modules", plan);
        setButtonModules(allModule);
        setExtractedIdOfModule(plan.modules.map((data) => data.module.id));
        console.log(plan);
        setSelectedPlan(plan);
        setPlanName(plan.plan_name);
        setPlanCategory(plan.catagory);
        setMostPopular(plan.most_popular);
        setMonthlyPrice(plan.monthly_price);
        setAnnualPrice(plan.annual_price);
        setDiscount(plan.discount);
        setUserLimit(plan.user_limit);
        setPlanDescription(plan.description);
        setModuleTableData(plan.modules);
        setShowPlanDetails(true);
        console.log(typeof plan.display, plan.display);
        setDisplay(plan.display);
        setEditMode(true);
    };
    const handleCreateNewPlan = () => {
        setSelectedPlan(null);
        setPlanName('');
        setMonthlyPrice(0);
        setAnnualPrice(0);
        setDiscount(null);
        setUserLimit(null);
        setPlanDescription('');
        setModuleTableData([]);
        setShowPlanDetails(true);
        setDisplay(false);
        setEditMode(false);
    }

    console.log("moduleTableData", moduleTableData);

    const handleAddNewModuleForNewPlan = (module) => {
        const newModule = {
            "id": module.id,
            "plan_id": module.id,
            "module": {
                "id": module.id,
                "module_name": module.module_name,
                "description": module.description,
                "logo": module.logo
            },
            "created_at": module.created_at,
            "updated_at": module.updated_at,
        };
        setModuleTableData(prevModuleData => [...prevModuleData, newModule]);
        console.log(newModule);
        if (!moduleIdsForNewCreate.includes(module.id)) {
            setModuleIdsForNewCreate(prevModuleIdsForCreation => [...prevModuleIdsForCreation, newModule.module.id]);
        } else {
            console.log("Module with the same ID already exists");
        }
    };
    useEffect(() => {
        if (!token) {
            navigate('/admin/login')
            //throw new Error('No token found');
        }

        dispatch(checkTokenExpiry());

        const fetchData = async () => {
            try {
                const planResponse = await getAllPlan(token);
                if (planResponse && planResponse.data) {
                    setTableData(planResponse.data);
                } else {
                    throw new Error('Empty response from server for plans');
                }
                const moduleResponse = await getAllModule(token);
                if (moduleResponse && moduleResponse.data) {
                    console.log(moduleResponse.data);
                    setAllModuleData(moduleResponse.data);
                } else {
                    throw new Error('Empty response from server for modules');
                }
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };
        if (token) {
            fetchData();
        }
    }, [token, dispatch]);

    const handleDisplayPlan = (e) => {
        setDisplay(e.target.checked);
    };
    const handleSaveChanges = async () => {
        const convertToNumber = (str) => {
            if (typeof str === 'string') {
                return Number(str.replace(/,/g, ''));
            }
            return Number(str);
        }
        try {
            const newPlanData = {
                plan_name: planName,
                category: planCategory,
                most_popular:mostPopular || false,
                description: planDescription,
                monthly_price: Number(monthlyPrice),
                annual_price: convertToNumber(annualPrice),
                user_limit: Number(userLimit),
                discount: Number(discount),
                display: display,
                module_id: moduleIdsForNewCreate
            }
            const updatedDataPayload = {
                plan_name: planName,
                category: planCategory,
                most_popular:mostPopular || false,
                description: planDescription,
                monthly_price: Number(monthlyPrice),
                annual_price: convertToNumber(annualPrice),
                user_limit: Number(userLimit),
                discount: Number(discount),
                display: display,
                module_id: extractedIdOfModule
            };
            let response;
            if (editMode) {
                if (!selectedPlan) {
                    throw new Error('No plan selected');
                }
                response = await editPlan(token, selectedPlan.id, updatedDataPayload);
                getAllPlan(token)
                    .then((res) => {
                        if (res && res.data) {
                            setTableData(res.data);
                        } else {
                            throw new Error('Empty response from server for plans');
                        }
                    })
                    .catch((error) => {
                        console.error('Failed to fetch data:', error);
                    });
            } else {
                response = await createPlan(token, newPlanData);
                getAllPlan(token)
                    .then((res) => {
                        if (res && res.data) {
                            setTableData(res.data);
                        } else {
                            throw new Error('Empty response from server for plans');
                        }
                    })
                    .catch((error) => {
                        console.error('Failed to fetch data:', error);
                    });
            }

            if (response) {

                if (editMode) {
                    const updatedTableData = tableData.map(plan => {
                        if (plan.id === selectedPlan.id) {
                            return {
                                ...plan,
                                plan_name: updatedDataPayload.plan_name,
                                description: updatedDataPayload.description,
                                monthlyPrice: updatedDataPayload.monthly_price,
                                annualPrice: updatedDataPayload.annual_price,
                                discount: updatedDataPayload.discount,
                                user_limit: updatedDataPayload.user_limit,
                                display: updatedDataPayload.display,
                                module_id: updatedDataPayload.module_id
                            };
                        }
                        return plan;
                    });
                    setTableData(updatedTableData);
                }
                setModuleTableData(prevModuleTableData => {
                    const updatedModules = prevModuleTableData.map(module => {
                        const found = extractedIdOfModule.includes(module.module.id);
                        return found ? module : null;
                    }).filter(module => module !== null);

                    const newModules = allModule.filter(module => extractedIdOfModule.includes(module.id));
                    return [...updatedModules, ...newModules];
                });
                setShowPlanDetails(false);
            } else {
                throw new Error('Failed to update plan');
            }
        } catch (error) {
            console.error('Error updating plan:', error);
        }
    };

    const handleDeleteModule = async (planId) => {
        try {
            const response = await deletepPlan(token, planId);
            if (response) {
                const updatedTableData = tableData.filter(plan => plan.id !== planId);
                setTableData(updatedTableData);
                setSelectedPlan(null);
            } else {
                throw new Error('Failed to delete plan');
            }
        } catch (error) {
            console.error('Error deleting plan:', error);
        }
    };

    const handleNewModuleAdd = (module) => {
        const newModule = {
            "id": module.id,
            "plan_id": module.id,
            "module": {
                "id": module.id,
                "module_name": module.module_name,
                "description": module.description,
                "logo": module.logo
            },
            "created_at": module.created_at,
            "updated_at": module.updated_at,
        };

        const moduleExists = moduleTableData.some(existingModule =>
            existingModule.module.id === newModule.module.id
        );

        if (!moduleExists) {
            setModuleTableData((prevModuleTableData) => [...prevModuleTableData, newModule]);
            setExtractedIdOfModule((prev) => {
                const newModuleIds = [...prev];
                console.log(newModuleIds, 'newModuleIds');
                if (newModuleIds.indexOf(module.id) === -1) {
                    newModuleIds.push(module.id);
                    console.log(newModuleIds);
                } else {
                    alert("Module Already exists");
                }
                return newModuleIds;
            });
            console.log("New Module added:", newModule);
        } else {
            console.log("Module with the same ID already exists");
        }

        console.log("moduleTableData", moduleTableData);
    };


    const handleStatusChange = async (plan, newStatus) => {
        try {
            const response = await changePlanStatus(token, plan.id, newStatus);
            console.log(response);
            if (response) {
                const updatedTableData = tableData.map((p) => {
                    if (p.id === plan.id) {
                        return { ...p, status: newStatus };
                    }
                    return p;
                });
                setTableData(updatedTableData);
                setSelectedPlan((prev) => (prev && prev.id === plan.id ? { ...prev, status: newStatus } : prev));
            } else {
                throw new Error('Failed to update plan status');
            }
        } catch (error) {
            console.error('Error updating plan status:', error);
        }
    };
    const getIconStyle = (status) => {
        return {
            color: status === 'Active' ? 'green' : 'red',
        };
    };
    const priorityChange = (row, handleStatusChange) => (
        <Popover id="statusChangePopover" className="priority-wrap">
            <div className="priority-list">
                {statusArray.map((status) => (
                    <div
                        key={status}
                        className="priority-set priority-list-item"
                        onClick={() => handleStatusChange(row, status)}
                    >
                        <i className={`fi ${status === 'Active' ? 'fi-ss-check-circle' : 'fi-sr-stop-circle'} mr-2`} style={getIconStyle(status)}></i>
                        <span>{status}</span>
                    </div>
                ))}
            </div>
        </Popover>
    );

    const handleDeleteModuleFromPlan = (id, moduleTableData) => {
        console.log(moduleTableData, id);
        // Filter out the module with the specified id
        const updatedModuleTableData = moduleTableData.filter((moduleData) =>
            moduleData.id !== id
        );
        setModuleTableData(updatedModuleTableData);

        // Extract the module IDs for the remaining modules
        const moduleIds = updatedModuleTableData.map(moduleData => moduleData.module.id);
        setExtractedIdOfModule(moduleIds);
    };

    const columns = [
        {
            name: "Plan Name",
            selector: (row) => row?.plan_name,
            sortable: true,
            width: "260px",
        },
        {
            name: "Monthly Price",
            selector: (row) => row?.monthly_price,
            sortable: true,
            width: "160px",
        },
        {
            name: "Annual Price",
            selector: (row) => row?.annual_price,
            sortable: true,
            width: "160px",
        },
        {
            name: "Status",
            selector: (row) => row?.status,
            sortable: true,
            width: "150px",
            cell: (row) => (
                <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="auto"
                    overlay={priorityChange(row, handleStatusChange)}
                >
                    <button className="table-data-change-btn">
                        <div className={`priority-set ${row?.status.toLowerCase()}`}>
                            <i className={`fi ${row.status === 'Active' ? 'fi-ss-check-circle' : 'fi-sr-stop-circle'} mr-2`} style={getIconStyle(row.status)}></i>
                            <span>{row?.status}</span>
                        </div>
                    </button>
                </OverlayTrigger>
            ),
        },
        {
            name: "Action",
            minWidth: "50px",
            cell: (row) => (
                <div className="d-flex">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                Edit
                            </Tooltip>
                        }
                    >
                        <button className="me-1 table-action-btn" onClick={() => handleShowplanDetails(row)}>
                            <i className="fi fi-rr-pencil"></i>
                        </button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                Delete
                            </Tooltip>
                        }
                    >
                        <button className="me-1 table-action-btn" onClick={() => handleDeleteModule(row.id)}>
                            <i className="fi fi-rr-trash text-exp-red"></i>
                        </button>
                    </OverlayTrigger>
                </div>
            ),
        },
    ];

    const modulesColumn = [
        {
            name: "Logo",
            selector: (row) => row.logo,
            sortable: true,
            width: "180px",
            cell: (row) => (
                <div style={{ textAlign: 'center' }}>
                    {row.logo ? (
                        <img src={`http://3.110.217.243:5500/uploads/${row.logo}`}
                            style={{
                                padding: '10px', // Adjust padding as needed
                                maxWidth: '60px', // Adjust max width as needed
                                height: 'auto', // Ensure image height adjusts automatically
                                borderRadius: '50%', // Apply border radius for rounded image
                                display: 'block', // Ensure image displays as a block element
                                margin: '0 auto' // Center align the image horizontally
                            }}
                        />
                    ) : (
                        'No Logo'
                    )}
                </div>
            ),
        },
        {
            name: "Module Name",
            selector: (row) => row.module?.module_name,
            sortable: true,
            width: "260px",
        },
        {
            name: "Description",
            selector: (row) => row.module?.description,
            sortable: true,
            width: "260px",
        },
        {
            name: "Action",
            minWidth: "310px",
            cell: (row) => (
                <div className="d-flex">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                Delete
                            </Tooltip>
                        }
                    >
                        <button className="me-1 table-action-btn" onClick={() => handleDeleteModuleFromPlan(row.id, moduleTableData)}>
                            <i className="fi fi-rr-trash text-exp-red"></i>
                        </button>
                    </OverlayTrigger>
                </div>
            ),
        },
    ];

    return (
        <>
            <div className='p-3'>
                <div class="row">
                    <div class="col d-flex justify-content-end py-3">
                        <Button onClick={handleCreateNewPlan} className='btn btn-primary'>Create New Plan</Button>
                    </div>
                </div>
                {tableData &&
                    <div className="card">
                        <div className="card-body p-0">
                            <DataTable
                                columns={columns}
                                data={tableData}
                                pagination={false}
                                theme="solarized"
                                striped
                                className='custom-table-wrap workflow-table-striped'
                            />
                        </div>
                    </div>
                }
            </div>

            <Modal show={showPlanDetails} onHide={handleClosePlanDetails} backdrop="static" centered size="xl" className="task-details-modal">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="task-title-edit-wrap">
                            <span className="task-name">{selectedPlan ? selectedPlan.plan_name : 'New Plan'}</span>
                            <span className="task-edit"><i className="fi fi-rr-pencil"></i></span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="task-details-modal-body-wrap">
                    <div className="task-details-modal-body">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <div className="status-item todo">
                                            <i className="fi fi-rr-user me-2"></i>
                                            <span>Plan Name</span>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <div className="profile-wrap">
                                            <div className="ps-2 text-truncate">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={planName}
                                                    onChange={(e) => setPlanName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <div className="status-item todo">
                                            <i className="fi fi-rr-dot-circle me-2"></i>
                                            <span>Status</span>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <div className="status-item inprogress">
                                            <i className="fi fi-rr-process me-2"></i>
                                            <span>{selectedPlan?.status}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <div className="status-item todo">
                                            <i className="fi fi-rr-calendar me-2"></i>
                                            <span>Created At</span>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <span className="planned-date-txt">
                                            {selectedPlan ? format(selectedPlan?.created_at, "d MMMM, yyyy") : null}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <div className="status-item todo">
                                            <i className="fi fi-rr-calendar me-2"></i>
                                            <span>Updated At</span>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <span className="planned-date-txt">
                                            {selectedPlan ? format(selectedPlan?.updated_at, "d MMMM, yyyy") : null}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="my-3" />
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <div className="status-item todo">
                                            <i className="fi fi-rr-calendar me-2"></i>
                                            <span>User Limit</span>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <span className="planned-date-txt">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={userLimit}
                                                onChange={(e) => setUserLimit(e.target.value)}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <div className="status-item todo">
                                            <i className="fi fi-rr-calendar me-2"></i>
                                            <span>Discount</span>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <span className="planned-date-txt">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={discount}
                                                onChange={(e) => setDiscount(e.target.value)}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <div className="status-item todo">
                                            <i className="fi fi-rr-calendar me-2"></i>
                                            <span>Monthly Price</span>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <span className="planned-date-txt">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={monthlyPrice}
                                                onChange={(e) => setMonthlyPrice(e.target.value)}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <div className="status-item todo">
                                            <i className="fi fi-rr-calendar me-2"></i>
                                            <span>Annual Price</span>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <span className="planned-date-txt">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={annualPrice}
                                                onChange={(e) => setAnnualPrice(e.target.value)}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <div className="status-item todo">
                                            <i className="fi fi-rr-calendar me-2"></i>
                                            <span>Display</span>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <span className="planned-date-txt">
                                            <input type='checkbox' checked={display} onChange={handleDisplayPlan} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <div className="status-item todo">
                                            <span>Plan Category</span>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <div className="profile-wrap">
                                            <div className="w-70 text-truncate">
                                                <select
                                                    className="form-control"
                                                    value={planCategory}
                                                    onChange={(e) => setPlanCategory(e.target.value)}
                                                >
                                                    <option value="" disabled>Select a Plan Category</option>
                                                    <option value="basic">Basic</option>
                                                    <option value="express">express</option>
                                                    <option value="business-growth">Business Growth</option>
                                                    <option value="enterprise">Enterprise</option>
                                                    {/* Add more options as needed */}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <div className="status-item todo">
                                            <i className="fi fi-rr-calendar me-2"></i>
                                            <span>Most Pouplar</span>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <span className="planned-date-txt">
                                            <input type='checkbox' checked={mostPopular} onChange={(e) => { setMostPopular(e.target.checked) }} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="my-3" />
                        <div className="description">
                            <h6>Description</h6>
                            <textarea
                                className="form-control"
                                rows="3"
                                value={planDescription}
                                onChange={(e) => setPlanDescription(e.target.value)}
                            />
                        </div>
                        <hr className="my-3" />
                        <div className="d-flex" aria-label="Module Buttons">
                            {editMode ? (
                                buttonModules && buttonModules.map(module => (
                                    <button
                                        key={module.id}
                                        type="button"
                                        className="btn btn-info mx-2"
                                        onClick={() => {
                                            if (editMode) {
                                                handleNewModuleAdd(module);
                                            } else {
                                                handleAddNewModuleForNewPlan(allModule);
                                            }
                                        }}
                                    >
                                        {module.module_name}
                                    </button>
                                ))
                            ) : (
                                allModule && allModule.map(module => (
                                    <button
                                        key={module.id}
                                        type="button"
                                        className="btn btn-info mx-2"
                                        onClick={() => handleAddNewModuleForNewPlan(module)}
                                    >
                                        {module.module_name}
                                    </button>
                                ))
                            )}
                        </div>

                        <hr className='my-3' />
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="card">
                                    {moduleTableData &&
                                        <div className="card-body p-0">
                                            <DataTable
                                                columns={modulesColumn}
                                                data={moduleTableData}
                                                pagination={false}
                                                theme="solarized"
                                                striped
                                                className='custom-table-wrap workflow-table-striped'
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <hr className="my-3" />
                        <Button variant="primary" onClick={handleSaveChanges}>
                            Save Changes
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AdminPlan;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { checkTokenExpiry } from '../../Redux/slices/adminAuthSlice';
import { getAllModule, deleteModule, editModule, createModule, changeModuleStatus } from '../../services/Admin/api';
import { Button, Dropdown, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { format } from 'date-fns';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const AdminModule = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [token, setToken] = useState(Cookies.get('adminToken'));
    console.log("token", token);

    const [tableData, setTableData] = useState([]);
    const [selectedModule, setSelectedModule] = useState(null);
    const [moduleName, setModuleName] = useState('');
    const [moduleDescription, setModuleDescription] = useState('');
    const [moduleLogo, setModuleLogo] = useState(null);
    const [moduleLogoURL, setModuleLogoURL] = useState('');
    const [isEditMode, setIsEditMode] = useState(false); // New state to manage mode

    const statusArray = ['Active', 'Suspend'];

    const [showModuleDetails, setShowModuleDetails] = useState(false);
    const handleCloseModuleDetails = () => setShowModuleDetails(false);
    const handleShowModuleDetails = (module) => {
        setSelectedModule(module);
        setModuleName(module.module_name);
        setModuleDescription(module.description);
        setModuleLogo(null); // Reset the file input
        setModuleLogoURL(`http://3.110.217.243:5500/uploads/${module.logo}`); // Set the URL for the existing logo
        setIsEditMode(true); // Set to edit mode
        setShowModuleDetails(true);
    };


    const handleShowCreateModule = () => {
        setSelectedModule(null);
        setModuleName('');
        setModuleDescription('');
        setModuleLogo(null); // Reset logo
        setModuleLogoURL('');
        setIsEditMode(false); // Set to create mode
        setShowModuleDetails(true);
    };

    useEffect(() => {
        if (!token) {
            navigate('/admin/login')
            //throw new Error('No token found');
        }
        dispatch(checkTokenExpiry());

        const fetchData = async () => {
            try {
                const response = await getAllModule(token);
                if (response && response.data) {
                    setTableData(response.data);
                } else {
                    throw new Error('Empty response from server');
                }
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };

        if (token) {
            fetchData();
        }
    }, [token, dispatch]);

    const handleSaveChanges = async () => {
        try {
            if (isEditMode && !selectedModule) {
                throw new Error('No module selected');
            }

            const formData = new FormData();
            formData.append('module_name', moduleName);
            formData.append('description', moduleDescription);

            if (moduleLogo) {
                formData.append('logo', moduleLogo); // Add logo to FormData
            }

            console.log(formData, "formdata during update");

            let response;
            if (isEditMode) {
                response = await editModule(token, selectedModule.id, formData);
            } else {
                response = await createModule(token, formData);
            }

            if (response) {
                const updatedTableData = isEditMode
                    ? tableData.map(module => {
                        if (module.id === selectedModule.id) {
                            return {
                                ...module,
                                module_name: moduleName,
                                description: moduleDescription,
                                logo: moduleLogo // Update logo URL in table data
                            };
                        }
                        return module;
                    })
                    : [...tableData, response.data];

                setTableData(updatedTableData);
                setShowModuleDetails(false);
            } else {
                throw new Error('Failed to save module');
            }
        } catch (error) {
            console.error('Error saving module:', error);
        }
    };

    

    const handleDeleteModule = async (moduleId) => {
        try {
            const response = await deleteModule(token, moduleId);
            if (response) {
                const updatedTableData = tableData.filter(module => module.id !== moduleId);
                setTableData(updatedTableData);
                setSelectedModule(null);
            } else {
                throw new Error('Failed to delete module');
            }
        } catch (error) {
            console.error('Error deleting module:', error);
        }
    };

    const handleStatusChange = async (module, newStatus) => {
        try {
            const response = await changeModuleStatus(token, module.id, newStatus);
            if (response) {
                const updatedTableData = tableData.map((m) => {
                    if (m.id === module.id) {
                        return { ...m, status: newStatus };
                    }
                    return m;
                });
                setTableData(updatedTableData);
                setSelectedModule((prev) => (prev && prev.id === module.id ? { ...prev, status: newStatus } : prev));
            } else {
                throw new Error('Failed to update module status');
            }
        } catch (error) {
            console.error('Error updating module status:', error);
        }
    };

    const getIconStyle = (status) => {
        return {
            color: status === 'Active' ? 'green' : 'red',
        };
    };
    const priorityChange = (row, handleStatusChange) => (
        <Popover id="plannedDate" className="pariority-wrap">
            <div className="pariority-list">
                {statusArray.map((status) => (
                    <div
                        key={status}
                        className="priority-set priority-list-item"
                        onClick={() => handleStatusChange(row, status)}
                    >
                        <i className={`fi ${status === 'Active' ? 'fi-ss-check-circle' : 'fi-sr-stop-circle'} mr-2`} style={getIconStyle(status)}></i>
                        <span>{status}</span>
                    </div>
                ))}
            </div>
        </Popover>
    );

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setModuleLogo(file);

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setModuleLogoURL(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setModuleLogoURL('');
        }
    };


    const columns = [
        {
            name: "Logo",
            selector: (row) => row.logo,
            sortable: true,
            width: "180px",
            cell: (row) => (
                <div style={{ textAlign: 'center' }}>
                    {row.logo ? (
                        <img src={`http://3.110.217.243:5500/uploads/${row.logo}`} 
                        style={{
                            padding: '10px', // Adjust padding as needed
                            maxWidth: '60px', // Adjust max width as needed
                            height: 'auto', // Ensure image height adjusts automatically
                            borderRadius: '50%', // Apply border radius for rounded image
                            display: 'block', // Ensure image displays as a block element
                            margin: '0 auto' // Center align the image horizontally
                        }} />
                    ) : (
                        'No Logo'
                    )}
                </div>
            ),
        },
        {
            name: "Module Name",
            selector: (row) => row.module_name,
            sortable: true,
            width: "260px",
        },
        {
            name: "Description",
            selector: (row) => row.description,
            sortable: true,
            width: "260px",
        },
        
        {
            name: "Status",
            selector: (row) => row.status,
            sortable: true,
            width: "150px",
            cell: (row) => (
                <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="auto"
                    overlay={priorityChange(row, handleStatusChange)}
                >
                    <button className="table-data-change-btn">
                        <div className={`priority-set ${row.status.toLowerCase()}`}>
                            <i className={`fi ${row.status === 'Active' ? 'fi-ss-check-circle' : 'fi-sr-stop-circle'} mr-2`} style={getIconStyle(row.status)}></i>
                            <span>{row.status}</span>
                        </div>
                    </button>
                </OverlayTrigger>
            ),
        },
        {
            name: "Action",
            minWidth: "310px",
            cell: (row) => (
                <div className="d-flex">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                Edit
                            </Tooltip>
                        }
                    >
                        <button className="me-1 table-action-btn" onClick={() => handleShowModuleDetails(row)}>
                            <i className="fi fi-rr-pencil"></i>
                        </button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                Delete
                            </Tooltip>
                        }
                    >
                        <button className="me-1 table-action-btn" onClick={() => handleDeleteModule(row.id)}>
                            <i className="fi fi-rr-trash text-exp-red"></i>
                        </button>
                    </OverlayTrigger>
                </div>
            ),
        },
    ];

    return (
        <>
            <div className='p-4'>
                <div className="row">
                    <div className="col d-flex justify-content-end py-3">
                        <Button onClick={handleShowCreateModule}>Create New Module</Button>
                    </div>
                </div>
                {tableData &&
                    <div className="card">
                        <div className="card-body p-0">
                            <DataTable
                                columns={columns}
                                data={tableData}
                                pagination={false}
                                theme="solarized"
                                striped
                                className='custom-table-wrap workflow-table-striped'
                            />
                        </div>
                    </div>
                }
            </div>
            <Modal show={showModuleDetails} onHide={handleCloseModuleDetails} backdrop="static" centered size="xl" className="task-details-modal">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="task-title-edit-wrap">
                            <span className="task-name">{isEditMode ? selectedModule.module_name : 'New Module'}</span>
                            <span className="task-edit"><i className="fi fi-rr-pencil"></i></span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="task-details-modal-body-wrap">
                    <div className="task-details-modal-body">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <div className="status-item todo">
                                            <i className="fi fi-rr-user me-2"></i>
                                            <span>Module Name</span>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <div className="profile-wrap">
                                            <div className="ps-2 text-truncate">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={moduleName}
                                                    onChange={(e) => setModuleName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isEditMode && (
                                <>
                                    <div className="col-lg-6">
                                        <div className="row align-items-center">
                                            <div className="col-4">
                                                <div className="status-item todo">
                                                    <i className="fi fi-rr-dot-circle me-2"></i>
                                                    <span>Status</span>
                                                </div>
                                            </div>
                                            <div className="col-8">
                                                <div className="status-item inprogress">
                                                    <i className="fi fi-rr-process me-2"></i>
                                                    <span>{selectedModule?.status}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="row align-items-center">
                                            <div className="col-4">
                                                <div className="status-item todo">
                                                    <i className="fi fi-rr-calendar me-2"></i>
                                                    <span>Created At</span>
                                                </div>
                                            </div>
                                            <div className="col-8">
                                                <span className="planned-date-txt">
                                                    {selectedModule ? format(new Date(selectedModule.created_at), "d MMMM, yyyy") : null}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="row align-items-center">
                                            <div className="col-4">
                                                <div className="status-item todo">
                                                    <i className="fi fi-rr-calendar me-2"></i>
                                                    <span>Updated At</span>
                                                </div>
                                            </div>
                                            <div className="col-8">
                                                <span className="planned-date-txt">
                                                    {selectedModule ? format(new Date(selectedModule.updated_at), "d MMMM, yyyy") : null}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <hr className="my-3" />
                        <div className="description">
                            <h6>Description</h6>
                            <textarea
                                className="form-control"
                                rows="3"
                                value={moduleDescription}
                                onChange={(e) => setModuleDescription(e.target.value)}
                            />
                        </div>
                        <hr className="my-3" />
                        <div className="logo-upload">
                            <h6>Logo</h6>
                            <input
                                type="file"
                                className="form-control"
                                onChange={handleFileChange}
                            />
                            {moduleLogoURL && (
                                <div className="mt-3">
                                    <img src={moduleLogoURL} alt="Module Logo" style={{ maxWidth: '100%', height: 'auto' }} />
                                </div>
                            )}
                        </div>
                        <hr className="my-3" />
                        <Button variant="primary" onClick={handleSaveChanges}>
                            Save Changes
                        </Button>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    );
};

export default AdminModule;

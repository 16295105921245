import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from '../landing/loder/Loader';
import { setPassword } from '../../services/User/api'; // Adjust the path as necessary
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setPassword as setPasswordRedux } from '../../Redux/slices/passwordSlice';
import Swal from 'sweetalert2';

function SetPassword() {

    const navigate = useNavigate();

    const email = useSelector((state) => state.email.email);
    const dispatch = useDispatch();
    console.log("email", email);
    const [password, setPasswordValue] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({
        password: '',
    });
    const validateUserInfo = () => {
        let isValid = true;
        let newErrors = { ...errors };

        // Password validation
        if (!password) {
            newErrors.password = 'Password is required';
            isValid = false;
        } else if (password.length < 8) {
            newErrors.password = 'Password must be at least 8 characters long';
            isValid = false;
        } else if (!/[A-Z]/.test(password)) {
            newErrors.password = 'Password must contain at least one uppercase letter';
            isValid = false;
        } else if (!/[0-9]/.test(password)) {
            newErrors.password = 'Password must contain at least one number';
            isValid = false;
        } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
            newErrors.password = 'Password must contain at least one special character';
            isValid = false;
        } else {
            newErrors.password = '';
        }

        // Update errors state (assuming you have a setErrors function to update the state)
        setErrors(newErrors);
        return isValid;

    };
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    const handleChangePassword = (e) => {
        dispatch(setPasswordRedux(e.target.value));
        setPasswordValue(e.target.value);
    };

    const handleChangeConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateUserInfo()) {
            if (password !== confirmPassword) {
                Swal.fire('Passwords do not match');
                return;
            }

            try {
                console.log(password, email);
                await setPassword(email, password);
                Swal.fire('Password set successfully');
                navigate('/register'); // Redirect to login or another appropriate page
            } catch (error) {
                Swal.fire(error.message);
            }
        }
    };

    return (
        <>
            {loading && (
                <div className={`appie-loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )}
            <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
                <div className='login-auth-wrap'>
                    <div className='container'>
                        <div className='row justify-content-between align-items-center'>
                            <div className='col-lg-5 col-md-6 col-sm-12'>
                                <div className='loginBox'>
                                    <div className="logo-wrap">
                                        <a href="/"><img src={process.env.PUBLIC_URL + 'assets/images/logo-navy.png'} alt="Growthh" className='img-fluid' /></a>
                                    </div>
                                    <h1 className='login-title mb-2'>
                                        Set Password
                                    </h1>
                                    <p className="small-text mb-4">Must be 8 or more characters and contain at least 1 number and 1 special character.</p>
                                    <div className='form-wrap'>
                                        <form className='w-100' onSubmit={handleSubmit}>
                                            <div className="form-group mb-4">
                                                <label className="form-label">Password</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text"><i className="fi fi-ss-key" /></div>
                                                    </div>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="Enter Password"
                                                        value={password}
                                                        onChange={handleChangePassword}
                                                    />
                                                </div>
                                            </div>
                                            {errors.password && <div className="text-danger">{errors.password}</div>}
                                            <div className="form-group mb-4">
                                                <label className="form-label">Confirm Password</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text"><i className="fi fi-ss-key" /></div>
                                                    </div>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="Enter Password"
                                                        value={confirmPassword}
                                                        onChange={handleChangeConfirmPassword}
                                                    />
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-exp-primary-dark w-100 f-s-18">Confirm</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-5 col-md-6 col-sm-12 login-right-col'>
                                <div className='login-right-wrap'>
                                    <div className='text-end imgBx'>
                                        <img src={process.env.PUBLIC_URL + 'assets/images/login-right.webp'} alt="login" />
                                    </div>
                                    <div className='textBx'>
                                        "Before Growthh, my to-do lists were scattered all around! Now, everything is in order and in one place."
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SetPassword;

// src/slices/emailSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendOtp } from '../../services/User/api';

export const sendOtpThunk = createAsyncThunk(
    'email/sendOtp',
    async (email, { dispatch }) => {
        await sendOtp(email);
        dispatch(setEmail(email));
    }
);

const initialState = {
    email: '',
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
};

const emailSlice = createSlice({
    name: 'email',
    initialState,
    reducers: {
        setEmail: (state, action) => {
            state.email = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendOtpThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(sendOtpThunk.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(sendOtpThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const { setEmail } = emailSlice.actions;
export default emailSlice.reducer;

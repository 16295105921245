import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../landing/loder/Loader';
import { verifyOtp } from '../../services/User/api'; 
import { useSelector } from 'react-redux';

function VerifyOtp() {
    const location = useLocation();
    const email = useSelector((state) => state.email.email);
    console.log(email);
    const [loading, setLoading] = useState(true);
    const [otp, setOtp] = useState(new Array(6).fill(''));
    // const [email, setEmail] = useState(location.state.email);
    const navigate = useNavigate(); 

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return false;

        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    const handlePaste = (e) => {
        const paste = e.clipboardData.getData('text');
        if (paste.length !== 6 || isNaN(paste)) return;

        setOtp(paste.split(''));
        e.target.blur();
    };

    const handleKeyDown = (event, index) => {
        if (event.keyCode === 8 && !otp[index] && index !== 0) {
            event.preventDefault();
            const previousElement = event.target.previousSibling;
            previousElement.focus();
            setOtp([...otp.map((d, idx) => (idx === index - 1 ? '' : d))]);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const otpString = otp.join('');
        try {
            const response = await verifyOtp(email, otpString);
            console.log('OTP verified successfully:', response);
            navigate('/set-password', { state: { email }}); 
        } catch (error) {
            console.error('Failed to verify OTP:', error);
        }
    };

    return (
        <>
            {loading && (
                <div className={`appie-loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )}
            <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
                <div className='login-auth-wrap'>
                    <div className='container'>
                        <div className='row justify-content-between align-items-center'>
                            <div className='col-lg-5 col-md-6 col-sm-12'>
                                <div className='loginBox'>
                                    <div className="logo-wrap">
                                        <a href="/"><img src={process.env.PUBLIC_URL + 'assets/images/logo-navy.png'} alt="Growthh" className='img-fluid' /></a>
                                    </div>
                                    <h1 className='login-title mb-2'>
                                        Enter Code
                                    </h1>
                                    <p className="small-text mb-4">A 6-digit verification code has been sent to your email. Please enter this code to proceed or <button className="gth-link-btn">Request a New Code</button>.</p>
                                    <div className='form-wrap'>
                                        <form className='w-100' onSubmit={handleSubmit}>
                                            <div className="otp-input mb-4">
                                                {otp.map((data, index) => (
                                                    <input
                                                        className="otp-field"
                                                        type="text"
                                                        name="otp"
                                                        maxLength="1"
                                                        key={index}
                                                        value={data}
                                                        onChange={(e) => handleChange(e.target, index)}
                                                        onFocus={(e) => e.target.select()}
                                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                                        onPaste={handlePaste}
                                                    />
                                                ))}
                                            </div>
                                            <button type="submit" className="btn btn-exp-primary-dark w-100 f-s-18">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-5 col-md-6 col-sm-12 login-right-col'>
                                <div className='login-right-wrap'>
                                    <div className='text-end imgBx'>
                                        <img src={process.env.PUBLIC_URL + 'assets/images/login-right.webp'} alt="login" />
                                    </div>
                                    <div className='textBx'>
                                        "Before Growthh, my to-do lists were scattered all around! Now, everything is in order and in one place."
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VerifyOtp;

import React, { createContext, useState, useContext, useRef,useEffect } from 'react';

// Create a Context with default values
export const HomeContext = createContext();


export const HomeContextProvider = ({ children }) => {
  const sectionRef = useRef(null);
  const [isToggled, setIsToggled] = useState(false);
  const [isLoggedIn,setIsLoggedIn] = useState(false);


  const scrollToSection = () => {
    sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

 

  const homeContextData = {
    sectionRef: sectionRef,
    scrollToSection: scrollToSection,
    scrollToTop:scrollToTop,
    isLoggedIn:isLoggedIn,
    setIsLoggedIn:setIsLoggedIn
  };

  

  return (

    <HomeContext.Provider value={{ homeContextData: homeContextData }}>
      {children}
    </HomeContext.Provider>
  );
};

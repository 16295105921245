import React, { useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'
import router from './DefineRouter'
import { HomeContextProvider } from '../Context/homeContext.jsx';

export const AllRoute = () => {

  return (
    <HomeContextProvider>
      <RouterProvider router={router} />
    </HomeContextProvider>
  )
}

// skipGetSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  action: '', // Initially empty
};

const skipGetSlice = createSlice({
  name: 'skipGet',
  initialState,
  reducers: {
    setAction: (state, action) => {
      state.action = action.payload;
    },
  },
});

export const { setAction } = skipGetSlice.actions;

export const selectAction = (state) => state.skipGet.action;

export default skipGetSlice.reducer;

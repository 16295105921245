import { createSlice } from '@reduxjs/toolkit';

const selectedPlanSlice = createSlice({
  name: 'selectedPlan',
  initialState: {
    plan: null,
    toggle: false, // Initial state of the toggle (true for monthly, false for annually)
    planType:'Monthly'
  },
  reducers: {
    selectPlan: (state, action) => {
      state.plan = action.payload;
    },
    setToggle: (state, action) => {
      state.toggle = action.payload;
      state.planType = (action.payload)?'Annual':'Monthly';
    },
  },
});

export const { selectPlan, setToggle,setPlanType } = selectedPlanSlice.actions;
export default selectedPlanSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const companyRegistrationSlice = createSlice({
    name: 'companyRegistration',
    initialState: {
        companyData: null,
    },
    reducers: {
        setCompanyData(state, action) {
            state.companyData = action.payload;
        },
    },
});

export const { setCompanyData } = companyRegistrationSlice.actions;
export default companyRegistrationSlice.reducer;

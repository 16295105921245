import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../landing/loder/Loader';
import { verifyOTP, resetPasswordForget } from '../../services/Admin/api'; // Adjust path as per your file structure

function VerifyAdminOtp() {
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email;
  const [loading, setLoading] = useState(true);
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [verificationError, setVerificationError] = useState('');
  const [isOtpVerified, setIsOtpVerified] = useState(false);

  useEffect(() => {
    setLoading(false); // Remove timeout for actual implementation
  }, []); // Ensure correct dependency array

  const handleOtpSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true); // Set loading to true before starting async operation

      const otpValue = otp.join('');
      const response = await verifyOTP(email, otpValue);

      if (response.data && response.data.isVerified) { // Assuming the response has a data object with isVerified
        console.log('OTP Verified Successfully:', response);
        setIsOtpVerified(true);
        setVerificationError(''); // Clear any previous errors
      } else {
        throw new Error('Failed to verify OTP');
      }

      setLoading(false); // Set loading to false after successful operation
    } catch (error) {
      console.error('Failed to verify OTP:', error.message);
      setVerificationError('Failed to verify OTP');
      setIsOtpVerified(false);

      setLoading(false);
    }
  };

  const handlePasswordSubmit = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      setVerificationError('Passwords do not match');
      return;
    }

    try {
      setLoading(true);
      const response = await resetPasswordForget(email, newPassword);
      console.log("response",response);
      if (response) { // Assuming the response has a success property
        console.log('Password Reset Successfully:', response);
        navigate('/admin/login');
        setVerificationError(''); 
        
      } else {
        throw new Error('Failed to reset password');
      }

      setLoading(false);
    } catch (error) {
      console.error('Failed to reset password:', error.message);
      setVerificationError('Failed to reset password');

      setLoading(false);
    }
  };

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    const updatedOtp = [...otp];
    updatedOtp[index] = element.value;
    setOtp(updatedOtp);
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData('text');
    if (paste.length !== 6 || isNaN(paste)) return;

    setOtp(paste.split(''));
    e.target.blur();
  };

  const handleKeyDown = (event, index) => {
    if (event.keyCode === 8 && !otp[index] && index !== 0) {
      event.preventDefault();
      const updatedOtp = [...otp];
      updatedOtp[index - 1] = '';
      setOtp(updatedOtp);
      if (index > 0) {
        const previousElement = event.target.previousSibling;
        previousElement.focus();
      }
    }
  };

  return (
    <>
      {loading && (
        <div className={`appie-loader ${loading ? 'active' : ''}`}>
          <Loader />
        </div>
      )}
      <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
        <div className='login-auth-wrap'>
          <div className='container'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-lg-5 col-md-6 col-sm-12'>
                <div className='loginBox'>
                  <div className="logo-wrap">
                    <a href="/"><img src={process.env.PUBLIC_URL + '/assets/images/logo-navy.png'} alt="Growthh" className='img-fluid' /></a>
                  </div>
                  <h1 className='login-title mb-2'>
                    Enter Code
                  </h1>
                  <p className="small-text mb-4">A 6-digit verification code has been sent to your email ({email}). Please enter this code to proceed or <button className="gth-link-btn">Request a New Code</button>.</p>
                  <div className='form-wrap'>
                    {!isOtpVerified ? (
                      <form className='w-100' onSubmit={handleOtpSubmit}>
                        <div className="otp-input mb-4">
                          {otp.map((data, index) => (
                            <input
                              className="otp-field"
                              type="text"
                              name="otp"
                              maxLength="1"
                              key={index}
                              value={data}
                              onChange={(e) => handleChange(e.target, index)}
                              onFocus={(e) => e.target.select()}
                              onKeyDown={(e) => handleKeyDown(e, index)}
                              onPaste={handlePaste}
                              autoFocus={index === 0}
                            />
                          ))}
                        </div>
                        <button type="submit" className="btn btn-exp-primary-dark w-100 f-s-18">Verify OTP</button>
                        {verificationError && <p className="text-danger mt-3">{verificationError}</p>}
                      </form>
                    ) : (
                      <form className='w-100' onSubmit={handlePasswordSubmit}>
                        <div className="form-group">
                          <label htmlFor="newPassword">New Password</label>
                          <input
                            type="password"
                            className="form-control"
                            id="newPassword"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="confirmPassword">Confirm Password</label>
                          <input
                            type="password"
                            className="form-control"
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                          />
                        </div>
                        <button type="submit" className="btn btn-exp-primary-dark w-100 f-s-18">Submit</button>
                        {verificationError && <p className="text-danger mt-3">{verificationError}</p>}
                      </form>
                    )}
                  </div>
                </div>
              </div>
              <div className='col-lg-5 col-md-6 col-sm-12 login-right-col'>
                <div className='login-right-wrap'>
                  <div className='text-end imgBx'>
                    <img src={process.env.PUBLIC_URL + '/assets/images/login-right.webp'} alt="login" />
                  </div>
                  <div className='textBx'>
                    "Before Growthh, my to-do lists were scattered all around! Now, everything is in order and in one place."
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VerifyAdminOtp;

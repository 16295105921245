import axios from "axios";
import config from '../utils/config.json'


const BASE_URL = config['PUBLIC_URL'];


export const getAllPlan = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/api/all-plans`);
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch active plan');
    }
};

export const sendOtp = async (email) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/company/registration/sent-otp`, { email });
        return response.data;
    } catch (error) {
        throw new Error('Failed to send OTP');
    }
};

export const verifyOtp = async (email, otp) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/company/registration/verify-otp`, { email, otp });
        return response.data;
    } catch (error) {
        throw new Error('Failed to verify OTP');
    }
};
export const setPassword = async (email, password) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/company/set-password`, {
            email,
            password
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to set password');
    }
};

export const registerCompany = async (formData) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/company/registration`, formData);
        return response.data;
    } catch (error) {
        throw new Error('Failed to register company');
    }
};

export const loginCompany = async (email,password) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/company/login`, { email,password });
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const logOut = async()=>{
    try{
        localStorage.removeItem('user');
        localStorage.removeItem('companyToken');
        window.location.href = '/';
    }catch(e){
        console.log('Error:',e);
    }
};

export const getCompanyProfile = async (token) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/company/profile`, {
            headers: {
                Authorization: `${token}`
            },
        });
        return response;
    } catch (error) {
        throw new Error('Failed to fetch orderhistory');
    }
};

export const updateCompanyProfile = async (token, userDetails) => {
    try {
        await axios.put(`${BASE_URL}/api/company/update-profile`, userDetails, {
            headers: {
                Authorization: `${token}`,
            },
        });
    } catch (error) {
        throw new Error('Failed to update profile');
    }
};

export const getAllCompanyUsers = async(token)=>{
    try{
       const response =  await axios.get(`${BASE_URL}/api/company/my-all-users`,  {
            headers: {
                Authorization: `${token}`,
            },
        });
        return response;
    }catch(e){
        throw new Error('Failed to update profile');
    }
}

export const createCompanyUser = async(userDetails,token)=>{
    try{
        const response =  await axios.post(`${BASE_URL}/api/company/user-registration`, userDetails, {
            headers: {
                Authorization: `${token}`,
            },
        });
        return response;
    }catch(e){
        throw new Error('Failed to create user');
    }
};

export const editCompanyUser = async(userDetails,token)=>{
    try{
        const response =  await axios.put(`${BASE_URL}/api/company/update-growth-users`, userDetails, {
            headers: {
                Authorization: `${token}`,
            },
        });
        return response;
    }catch(e){
        throw new Error('Failed to update user');
    }
};

export const deleteCompanyUser = async(userId,token)=>{
    try{
        const response =  await axios.delete(`${BASE_URL}/api/company/delete-user-in-growthh/${userId}`, {
            headers: {
                Authorization: `${token}`,
            },
        });
        return response;
    }catch(e){
        throw new Error('Failed to delete user');
    }
};



export const getAllCompanyModules = async(token)=>{
    try{
        const response =  await axios.get(`${BASE_URL}/api/company/my-purchased-modules`,{
            headers: {
                Authorization: `${token}`,
            },
        });
        return response;
    }catch(e){
        throw new Error('Failed to fetch copmany modules');
    }
};

export const orderPost = async (plan_id,plan_type,token) => {
    try {
        const response = await axios.post(
            `${BASE_URL}/api/order/create-order`,
            { plan_ids:plan_id,plan_type:plan_type },
            {
                headers: {
                    Authorization: `${token}`, // Assuming Bearer token is used
                },
            }
        );
        return response.data;
    } catch (error) {
        throw new Error('Failed to create order');
    }
};

export const orderUpdate = async (orderId,plan_ids,plan_type,token) => {
    try {
        const response = await axios.put(
            `${BASE_URL}/api/order/update-order`,
            { order_id:orderId,plan_ids:plan_ids,plan_type:plan_type },
            {
                headers: {
                    Authorization: `${token}`, // Assuming Bearer token is used
                },
            }
        );
        return response.data;
    } catch (error) {
        throw new Error('Failed to create order');
    }
};

export const purchasePost = async (data, token) => {
    try {
        const response = await axios.post(
            `${BASE_URL}/api/purchase`,
            data,
            {
                headers: {
                    Authorization: `${token}`, // Assuming Bearer token is used
                },
            }
        );
        return response.data;

        
    } catch (error) {
        throw new Error('Failed to create order');
    }
};

export const getAllOrders = async (token) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/order/fetch-my-pending-orders`, {
            headers: {
                Authorization: `${token}`
            }
        });

        console.log(response,'allorders');
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch active plan');
    }
};

export const getAllPaymentHistory = async (token) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/purchase/all-purchases`, {
            headers: {
                Authorization: `${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch active plan');
    }
};


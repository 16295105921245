import React, { useEffect, useState } from 'react';
import { getAllPlan } from '../../services/User/api';
import { useSelector, useDispatch } from 'react-redux';
import { addAddonModule, removeAddonModule, toggleAddonModule } from '../../Redux/slices/addonModulesSlice';
import './module.min.css';

function LnModule(props) {
  const { addOns } = props;
  const [displayFalsePlans, setDisplayFalsePlans] = useState([]);
  const checkedPlan = useSelector((state) => state.selectedPlan.plan);
  const addonModules = useSelector((state) => state.addonModules.addonModules);
  const [moduleIds, setModuleIds] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchPlans();
  }, []);

  useEffect(() => {
    // Extract module IDs from checkedPlan whenever it changes
    if (checkedPlan && checkedPlan.modules) {
      const moduleNames = checkedPlan.modules.map((mod) => mod.module.module_name);
      if (addOns) {
        const newAddOns = addOns.addonModules.map((mod) => mod.plan_name);
        const mergedArray = moduleNames.concat(newAddOns);
        setModuleIds(mergedArray);
      }
      else {
        setModuleIds(moduleNames);
      }
    }else if(addOns){
      console.log(addOns,'addOns');
      const planNames = addOns?.map((plan)=>plan.plan_name);
      setModuleIds(planNames);
    }
  }, [checkedPlan, addOns]);

  const fetchPlans = async () => {
    getAllPlan().then((response) => {
      const filteredPlans = response.data.filter(
        (item) => item.display === false && item.status === 'Active'
      );
      console.log(filteredPlans,response,'filteredPlans');

      setDisplayFalsePlans(filteredPlans);
    }).catch((error) => {
      console.error('Error fetching plans:', error.message);
    });
    // Filter plans based on display and status criteria
  };

  const handleToggleAddonModule = (plan) => {

    const existingAddon = addonModules.find(mod => mod.id === plan.id);
    if (existingAddon || existingAddon !== undefined) {
      dispatch(removeAddonModule(plan));
    } else {
      dispatch(addAddonModule(plan));
    }

    setModuleIds((prev) => {
      const newData = [...prev];
      const moduleNames = plan.modules.map(module => module.module.module_name);
      // Check if any of the module names in plan.modules is in moduleIds
      const hasMatchingModule = moduleNames.some(name => newData.includes(name));
    
      let updatedData = [];
    
      if (hasMatchingModule) {
        // Remove any matching module names from moduleIds
        updatedData = newData.filter(data => !moduleNames.includes(data));
      } else {
        // Add all module names from plan.modules to moduleIds
        updatedData = [...newData, ...moduleNames];
      }
    
      return updatedData;
    });
  };
  return (
    <div className="module-list-wrap">
      {displayFalsePlans.map((plan) => (
        <label className="custom-checkbox module-checkbox-list-item" key={plan.id}>
          <input
            type="checkbox"
            checked={plan.modules.some(module => moduleIds.includes(module.module.module_name))}
            onChange={() => handleToggleAddonModule(plan)}
          />
          <span className="checkmark"></span>
          <span className="module-icon"><i className="fi fi-ss-department"></i></span>
          <span className="module-name">{plan.plan_name}</span>
        </label>
      ))}
    </div>
  );
}

export default LnModule;

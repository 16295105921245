// orderSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    orderData: null,
    loading: false,
    error: null,
};

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        placeOrderStart(state) {
            state.loading = true;
            state.error = null;
        },
        placeOrderSuccess(state, action) {
            state.loading = false;
            state.orderData = action.payload;
            state.error = null;
        },
        placeOrderFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        resetOrderState(state) {
            state.orderData = null;
            state.loading = false;
            state.error = null;
        },
    },
});

export const { placeOrderStart, placeOrderSuccess, placeOrderFailure, resetOrderState } = orderSlice.actions;

export default orderSlice.reducer;
